.wrapper {
  .hero {
    height: calc(100svh - 70px);
    display: flex;
    justify-content: space-between;
    max-height: 1080px;

    .textContent {
      height: 100%;
      margin-left: 134px;
      gap: 30px;
      display: flex;
      flex-direction: column;
      place-content: center;
      h1 {
        color: #00864a;
        font-family: Inter;
        font-size: 54.375px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px; /* 132.414% */
      }
      p {
        color: #000;
        font-family: Varela;
        max-width: 450px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 150% */
      }
      button {
        margin-top: 2px;
        color: #fff;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
        display: flex;
        width: 210.98px;
        height: 56px;
        padding: 18px 55.035px 19px 56px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 40px;
        background: #eb578f;
        backdrop-filter: blur(12.5px);
        transition: 0.3s;

        &:hover {
          background-color: #dc2268;
          transition: 0.1s;
        }
      }
    }

    .burgerWrapper {
      flex-shrink: 0;
      background: url(../../assets/heroFrameBackground.png) lightgray 0px 0px /
        100% 100% no-repeat;
      background-color: #fff;
      width: calc(72svh - 70px); //math
      height: calc(100%);

      display: flex;
      align-items: center;
      justify-content: center;

      .swiperHero {
        border-radius: 25px;
        width: 500px;
        height: 500px;

        .item {
          width: 500px;
          height: 500px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 100px 0;

    h2 {
      margin-bottom: 41px;
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      padding: 0 40px;
      gap: 50px;
      row-gap: 20px;
      justify-content: center;

      .filter {
        border-radius: 40px;
        border: 1px solid #dc2268;
        background: #fff;
        padding: 12px 36px;
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-wrap: nowrap;
        text-transform: uppercase;
        transition: 0.3s;

        &:hover {
          transition: 0.1s;
          background-color: #eb578f;
          color: #fff;
        }
      }
      .selectedFilter {
        text-wrap: nowrap;
        transition: 0.1s;
        border-radius: 40px;
        border: 1px solid #dc2268;
        background: #dc2268;
        padding: 12px 36px;
        color: #fff;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .menuPreview {
      width: 100%;
      overflow-x: hidden;
      height: 600px;
      padding: 77px 150px 0 150px;

      .item {
        transition: background-color 0.2s;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 18px;
        padding: 20px 0;
        height: calc(100% - 2px);
        border: 1px solid #ecece5;
        border-radius: 25px;

        &:hover {
          //background-color: #dc2268;
          transition: background-color 0.1s;

          //.divider {
          //  transition: background-color 0.1s;
          //  background-color: #fff;
          //}

          //p {
          //  color: #fff;
          //}

          img {
            transition: 0.1s;
            transform: scale(1.1);
          }
        }

        .divider {
          transition: background-color 0.3s;
          width: 116px;
          height: 10px;
          flex-shrink: 0;
          background-color: #dc2268;
        }

        p {
          text-align: center;
          padding: 0 33px;
          color: rgba(0, 0, 0, 0.5);
          text-align: center;
          font-family: Varela;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 150% */
          margin-top: 2px;
        }

        img {
          transition: 0.3s;
          transform: scale(1);
          max-width: 250px;
          max-height: 250px;
          height: auto !important;
        }
      }
    }

    .scrollButtonsWrapper {
      margin-top: 30px;
      height: 50px;
      display: flex;
      gap: 15px;

      .menuLeft {
        width: 48px;
        height: 48px;
      }
      .menuRight {
        width: 48px;
        height: 48px;
      }

      svg {
        height: 100%;
      }
    }
  }

  .section4 {
    overflow: hidden;
    height: 792px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .background {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

    h2 {
      margin-top: 160px;
      margin-bottom: 20px;
      max-width: 430px;
      color: #192630;
      text-align: center;
      font-family: Inter;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 57.6px; /* 137.143% */

      span {
        color: #00864a;
        font-family: Inter;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: 57.6px;
      }
    }

    .reviews {
      position: relative;
      padding: 0 96px;
      width: 100%;

      .item {
        border-radius: 25px;
        border: 1px solid #ecece5;
        background: #fff;
        display: flex;
        gap: 16px;

        .pfp {
          margin: 78px 16px 78px 25px;
          position: relative;
          flex-shrink: 0;
          width: 195px;
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          .avatar {
            position: absolute;
            border-radius: 99999px;
            width: 60px;
            height: 60px;
          }

          .blob {
            position: absolute;
            width: 64px;
            height: 64px;
            flex-shrink: 0;

            border-radius: 25px;
            background: #dce442;
          }
        }

        .reviewInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 65px 18px 36px 0;

          h3 {
            margin-bottom: 34px;
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 27.5px;
            font-style: normal;
            font-weight: 600;
            line-height: 38.4px; /* 139.636% */
          }

          p {
            margin-bottom: 17px;
            color: #000;
            font-family: Varela;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
          }

          .starWrapper {
            display: flex;
            gap: 4px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .reviewLeft {
        position: absolute;
        left: 25px;
        top: calc(50% - 12.5px);
        z-index: 10;
        width: 36px;
        height: 36px;
      }
      .reviewRight {
        transform: rotate(180deg);
        position: absolute;
        right: 25px;
        top: calc(50% - 12.5px);
        z-index: 10;
        width: 36px;
        height: 36px;
      }
    }
  }
  .section5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    h2 {
      background-color: #00864a;
      padding-top: 115px;
      width: 100%;
      color: #fff;
    }

    p {
      background-color: #00864a;
      padding-top: 33px;
      width: 100%;
      padding-bottom: 56px;
      color: #e2e2e2;
      text-align: center;
      font-family: Varela;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    .recipesWrapper {
      display: flex;
      width: 100%;
      .recipe {
        aspect-ratio: 0.7;
        max-height: 800px;
        &:hover {
          transform: scale(1.1);
          z-index: 10;
          transition: 0.1s;

          p {
            transform: scale(0.93);
            bottom: 35px;
            transition: 0.1s;
          }
        }
        cursor: pointer;
        transition: 0.3s;
        z-index: 5;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        .recipeImg {
          width: 100%;
          height: 100%;
          flex: 1 1 0;
          object-fit: cover;
        }
        p {
          width: unset;
          background-color: unset;
          text-shadow: 1px 1px 1em black, 0 0 1em black, 0 0 1em black;
          transition: 0.3s;
          border-radius: 10px;
          padding: 1px 5px;
          position: absolute;
          bottom: 20px;
          margin: 0;
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 18.438px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }

    .viewRecipesWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin: 81px 0 116px 0;
      background-color: #fff;
      transition: 0.1s;
      cursor: pointer;

      &:hover {
        //margin-left: 6px;
        gap: 12px;
        transition: 0.1s;
      }

      p {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 17.016px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.2px; /* 148.099% */
        letter-spacing: 0.36px;
        padding: 0;
        background-color: unset;
      }
    }
  }

  .section6 {
    h2 {
      margin-bottom: 78px;
    }
  }

  .section7 {
    margin-top: 106px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    padding: 225px 0;

    h2 {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 47.531px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      max-width: 720px;
      text-align: center;
      color: #fff;
      text-align: center;
      font-family: Varela;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28.8px; /* 160% */
    }

    button {
      border-radius: 40px;
      background: #fff;
      backdrop-filter: blur(12.5px);
      padding: 18px 56px;
      cursor: pointer;

      color: #000;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      transition: 0.3s;

      &:hover {
        background-color: #e3e3e3;
        transition: 0.1s;
      }
    }

    img {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .section8 {
    padding: 96px 191px;

    h2 {
      margin-bottom: 103px;
    }

    iframe {
      width: 100%;
      height: 450px;
    }

    .locationsWrapper {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      gap: 60px;

      .location {
        padding: 20px 0;
        background-color: #fbfbf9;
        max-width: 471px;
        width: 33%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: column;

        h4 {
          margin-bottom: 23px;
          color: #3f3f3f;
          text-align: center;
          font-family: Inter;
          font-size: 19.688px;
          font-style: normal;
          font-weight: 600;
          line-height: 33.6px; /* 170.667% */
        }

        p {
          color: #676767;
          text-align: center;
          font-family: Varela;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 25.6px; /* 160% */
          margin-bottom: 21px;
        }

        .mapsWrapper {
          gap: 8px;
          display: flex;
          cursor: pointer;

          p {
            color: #000;
            text-align: center;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 25.2px; /* 140% */
            letter-spacing: 0.36px;
            text-transform: uppercase;
            margin: 0;
          }

          button {
            cursor: pointer;
            flex-shrink: 0;
            width: 25px;
            height: 25px;
            border-radius: 25px;
            background: #87e874;

            p {
              color: #000;
              text-align: center;
              font-family: Rye;
              font-size: 13.3px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .wrapper {
    margin-top: 60px;
    .hero {
      flex-direction: column;
      height: unset;

      .textContent {
        padding: 20px;
        margin-left: 0px;

        h1 {
          font-size: 40px;
          line-height: 50px;
        }

        p {
          width: unset;
        }
      }

      .burgerWrapper {
        width: 100%;

        .swiperHero {
          margin: 10px;
          width: 90vw;
          height: 90vw;

          .item {
            width: 90vw;
            height: 90vw;
          }
        }
      }
    }

    .section2 {
      h2 {
        font-size: 36px;
      }
      .menuPreview {
        .item {
          &:hover {
            //background-color: #dc2268;
            transition: unset;

            //.divider {
            //  transition: background-color 0.1s;
            //  background-color: #fff;
            //}

            //p {
            //  color: #fff;
            //}

            img {
              transition: none;
              transform: none;
            }
          }
        }
      }

      .filters {
        gap: 20px;
        padding: 0 10px;
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      .menuPreview {
        padding: 10%;

        .item {
          p {
            white-space: wrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .section4 {
      overflow: unset;
      height: unset;

      .background {
        object-fit: cover;
      }

      h2 {
        padding: 0 20px;
        margin-top: 0px;
        line-height: 40px;
      }
      .reviews {
        padding: 5% 10%;

        .item {
          align-items: center;
          flex-direction: column;

          .pfp {
            margin: 20px 0 10px 0;
          }

          .reviewInfo {
            padding: 0 30px 20px 30px;

            h3 {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .section5 {
      h2 {
        padding-top: 40px;
        font-size: 40px;
      }

      p {
        padding: 10px 10px 20px 10px;
      }

      .recipe {
        max-height: 500px !important;
      }

      .viewRecipesWrapper {
        margin: 20px 0 30px 0;
      }
    }

    .section6 {
      h2 {
        margin-bottom: 10px;
      }

      .blogpostsWrapper {
        padding: 0 15px;
      }
    }

    .section7 {
      gap: 20px;
      padding: 225px 10px;
      h2 {
        font-size: 40px;
        line-height: 40px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .section8 {
      padding: 30px 20px 20px 20px;

      h2 {
        margin-bottom: 40px;
      }

      .locationsWrapper {
        flex-direction: column;
        gap: 20px;

        .location {
          width: 100%;
        }
      }
    }
  }
}
