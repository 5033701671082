.menuItemWrapper {
  width: 100%;
  height: calc(100vh - 70px);

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    .itemImage {
      width: 100%;
      height: auto;
    }
    .itemDetails {
      width: 100%;
    }
  }

  .itemImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: calc(100vh - 70px);
    background: #f4f2eb;

    @media screen and (max-width: 900px) {
      width: 100%;
      height: auto;
    }
    img {
      width: 60%;
      mix-blend-mode: multiply !important;
    }
  }
  .itemDetails {
    padding: 60px 70px;
    width: 50%;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      width: 100%;
      gap: 60px;
    }
    .itemContent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .title {
        display: flex;
        flex-direction: column;
        gap: 5px;
        h1 {
          color: #000;
          font-family: Rimouski;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 60px; /* 150% */
        }
        h4 {
          color: #000;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px; /* 150% */
          text-transform: uppercase;
        }
      }
      .content {
        width: 100%;
        color: rgba(0, 0, 0, 0.7);
        font-family: Varela;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
        align-self: stretch;
        padding: 20px 0px;
        border-top: rgba(0, 0, 0, 0.15) solid 1px;
        border-bottom: rgba(0, 0, 0, 0.15) solid 1px;
      }
      .medical {
        color: #000;
        font-family: Varela;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
        text-decoration-line: underline;
      }
    }
  }
  .buttonWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    span {
      width: 100%;
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px; /* 156.25% */
      text-transform: uppercase;
    }

    .certificatesWrapper {
      display: flex;
      gap: 13px;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 37px;
        aspect-ratio: 1;
      }
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    a {
      display: flex;
      padding: 5px 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 40px;
      backdrop-filter: blur(12.5px);
      align-self: stretch;
      width: 33%;
      height: 40px;
      flex-grow: 1;
    }
    .wolt {
      background: #52bcdd;
      transition: all 0.3s;
      &:hover {
        background: #3e9fbd;
      }
    }
    .lieferando {
      background: #ff8000;
      transition: all 0.3s;
      &:hover {
        background: #e67300;
      }
    }
    .uberEats {
      background: #000;
      transition: all 0.3s;
      &:hover {
        background: #353535;
      }
    }
  }
  .companyLogos {
    width: auto;
    max-height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .menuItemWrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      .itemImage {
        width: 100%;
        height: auto;
      }
      .itemDetails {
        width: 100%;
      }
    }

    .itemImage {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 50vh !important;
      background: #f4f2eb;

      @media screen and (max-width: 900px) {
        width: 100%;
        height: auto;
      }
      img {
        width: 60%;
      }
    }
    .itemDetails {
      padding: 60px 25px;
      width: 100%;
      height: calc(100vh - 70px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: 900px) {
        width: 100%;
        gap: 60px;
      }
      .itemContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        .title {
          display: flex;
          flex-direction: column;
          gap: 5px;
          h1 {
            color: #000;
            font-family: Rimouski;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px; /* 150% */
          }
          h4 {
            color: #000;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 150% */
            text-transform: uppercase;
          }
        }
        .content {
          width: 100%;
          color: rgba(0, 0, 0, 0.7);
          font-family: Varela;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 150% */
          align-self: stretch;
          padding: 20px 0px;
          border-top: rgba(0, 0, 0, 0.15) solid 1px;
          border-bottom: rgba(0, 0, 0, 0.15) solid 1px;
        }
        .medical {
          color: #000;
          font-family: Varela;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 150% */
          text-decoration-line: underline;
        }
      }
    }
    .buttonWrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;

      span {
        width: 100%;
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px; /* 156.25% */
        text-transform: uppercase;
      }

      .certificatesWrapper {
        display: flex;
        gap: 13px;
        justify-content: center;
        margin-bottom: 20px;

        img {
          width: 37px;
          aspect-ratio: 1;
        }
      }
    }
    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      a {
        display: flex;
        padding: 5px 56px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 40px;
        backdrop-filter: blur(12.5px);
        align-self: stretch;
        width: 33%;
        height: 40px;
        flex-grow: 1;
      }
      .wolt {
        background: #52bcdd;
        transition: all 0.3s;
        &:hover {
          background: #3e9fbd;
        }
      }
      .lieferando {
        background: #ff8000;
        transition: all 0.3s;
        &:hover {
          background: #e67300;
        }
      }
      .uberEats {
        background: #000;
        transition: all 0.3s;
        &:hover {
          background: #353535;
        }
      }
    }
    .companyLogos {
      width: auto;
      max-height: 30px;
    }
  }
}
