.menuWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heroSection {
  background: #00864a;
  width: 100%;
  height: 640px;
  display: flex;
  flex-direction: row;

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 32px;

      max-width: 490px;

      .button {
        padding: 15px 40px;
        background-color: #dc2268;
        border-radius: 40px;
        text-transform: uppercase;

        text-decoration: none;
        color: #fff;
        text-align: center;
        font-family: Lato;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 107.692% */
      }

      .bigText {
        color: #fff;
        font-family: Rimouski;
        font-size: 60px;
        font-weight: 300;
        line-height: 72px; /* 120% */
      }
      .subTitle {
        color: #fff;
        font-family: Varela;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px; /* 150% */
      }
    }
  }
  .right {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.menusButtons {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 36px;

  .button {
    padding: 15px 40px;
    background-color: #dc2268;
    border-radius: 40px;

    text-decoration: none;
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
    text-transform: uppercase;
  }
  margin-top: 64px;
}

.menuSection {
  display: flex;
  width: 100%;
  padding: 40px 79px 40px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  .categoryFilters {
    z-index: 5;

    position: sticky;
    top: 10px;
    display: flex;
    height: 100%;
    padding: 18px 5%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    background: #fff;
    box-shadow: 6px 9px 24px 0px rgba(0, 0, 0, 0.07);
    flex-wrap: wrap;
    .categoryButton {
      display: flex;
      padding: 11px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-radius: 40px;
      background: rgba($color: #00864a, $alpha: 0.1);
      transition: all 0.3s ease;
      color: #00864a;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 100% */
      text-transform: uppercase;
      width: fit-content;
      &:hover {
        background-color: #00864a;
        color: white;
      }

      &.active {
        background-color: #00864a;
        color: white;
      }
    }
  }
}

.foodTypesFilter {
  z-index: 5;
  position: sticky;
  top: 110px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  min-width: 170px;
  @media screen and (max-width: 1440px) {
    min-width: 152px;
  }

  h2 {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
  }

  .typeFilterItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    .foodTypeOption {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      font-size: 18px;
      color: #7e7e7e;
      font-family: Lato;

      input[type="checkbox"] {
        opacity: 1;
        width: 20px;
        height: 20px;
        border-radius: 50%;

        background-size: 100%;

        cursor: pointer;
        appearance: none;
        background-color: transparent;
        transition: background-color 0.3s, border-color 0.3s;

        &:checked {
          + span {
            opacity: 1;
          }
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
        }
      }

      span {
        color: #000;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        text-transform: uppercase;
        opacity: 0.5;
        transition: color 0.3s; /* Smooth transition for color change */
      }

      &.active {
        span {
          opacity: 1 !important;
        }
      }
    }
  }
}

.menuItemsWithTypes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4.37%;
  width: 100%;
}
.menuItems,
.skeletonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.menuItem {
  z-index: 1;
  text-decoration: none;
  display: flex;
  width: 338px;
  align-self: stretch;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  border: 1px solid #ecece5;
  background: #fff;
  transition: all 0.3s;
  &:hover {
    .itemImage {
      img {
        transition: all 0.3s;

        scale: 1.1;
      }
    }
  }
  .itemImage {
    transition: all 0.3s;

    img {
      transition: all 0.5s;

      max-width: 250px;
      max-height: 250px;
      height: auto !important;
    }
  }
  .itemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
    height: fit-content;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      h4 {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px; /* 150% */
        text-transform: uppercase;
      }
      .block {
        width: 110px;
        height: 10px;
        background: #dc2268;
      }
    }
    p {
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      font-family: Varela;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
    }
    span {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
      text-transform: uppercase;
    }
  }
}

.skeleton {
  .itemImageSkeleton {
    width: 180px;
    height: 180px;
    background-color: #e0e0e0;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
  }

  .titleSkeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .titleBlockSkeleton {
      width: 60%;
      height: 27px;
      background-color: #e0e0e0;
      border-radius: 5px;
      animation: pulse 1.5s infinite;
    }

    .blockSkeleton {
      width: 110px;
      height: 10px;
      background-color: #e0e0e0;
      animation: pulse 1.5s infinite;
      border-radius: 5px;
    }
  }

  .descriptionSkeleton {
    width: 80%;
    height: 21px;
    background-color: #e0e0e0;
    animation: pulse 1.5s infinite;
    border-radius: 5px;
  }

  .priceSkeleton {
    width: 40%;
    height: 30px;
    background-color: #e0e0e0;
    animation: pulse 1.5s infinite;
    border-radius: 5px;
  }
}

.buttonWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  span {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px; /* 156.25% */
    text-transform: uppercase;
  }

  .certificatesWrapper {
    display: flex;
    gap: 13px;
    justify-content: center;
    margin-bottom: 20px;

    img {
      width: 37px;
      aspect-ratio: 1;
    }
  }
}
.buttons {
  width: 100%;
  margin-bottom: 50px;
  padding: 0 20%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;

  a {
    display: flex;
    padding: 5px 56px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 40px;
    backdrop-filter: blur(12.5px);
    align-self: stretch;
    width: 33%;
    height: 40px;
    flex-grow: 1;
    max-width: 300px;
  }
  .wolt {
    background: #52bcdd;
    transition: all 0.3s;
    &:hover {
      background: #3e9fbd;
    }
  }
  .lieferando {
    background: #ff8000;
    transition: all 0.3s;
    &:hover {
      background: #e67300;
    }
  }
  .uberEats {
    background: #000;
    transition: all 0.3s;
    &:hover {
      background: #353535;
    }
  }
}
.companyLogos {
  width: auto;
  max-height: 30px;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

@media screen and (max-width: 768px) {
  .menuWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .heroSection {
    background: #00864a;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    gap: 25px;
    .left {
      padding: 0px 25px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 32px;

        max-width: 490px;

        .button {
          padding: 15px 40px;
          background-color: #dc2268;
          border-radius: 40px;
          text-transform: uppercase;

          text-decoration: none;
          color: #fff;
          text-align: center;
          font-family: Lato;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px; /* 107.692% */
        }

        .bigText {
          color: #fff;
          font-family: Rimouski;
          font-size: 32px;
          font-weight: 300;
          line-height: 40px; /* 120% */
          text-align: center;
        }
        .subTitle {
          color: #fff;
          font-family: Varela;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px; /* 150% */
          text-align: center;
        }
      }
    }
    .right {
      width: 100%;
      img {
        margin-bottom: -4px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .menusButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 36px;

    .button {
      padding: 15px 40px;
      background-color: #dc2268;
      border-radius: 40px;

      text-decoration: none;
      color: #fff;
      text-align: center;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 107.692% */
      text-transform: uppercase;
    }
    margin-top: 64px;
  }

  .menuSection {
    display: flex;
    width: 100%;
    padding: 40px 16px 40px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .categoryFilters {
      z-index: 5;

      position: relative;
      top: 10px;
      display: flex;
      height: 100%;
      padding: 18px 5%;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.02);
      background: #fff;
      box-shadow: 6px 9px 24px 0px rgba(0, 0, 0, 0.07);
      flex-wrap: wrap;
      .categoryButton {
        display: flex;
        padding: 11px 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        border-radius: 40px;
        background: rgba($color: #00864a, $alpha: 0.1);
        transition: all 0.3s ease;
        color: #00864a;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        text-transform: uppercase;
        width: fit-content;
        &:hover {
          background-color: #00864a;
          color: white;
        }

        &.active {
          background-color: #00864a;
          color: white;
        }
      }
    }
  }

  .foodTypesFilter {
    z-index: 5;
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    min-width: 170px;
    width: 100;
    margin-bottom: 40px;
    @media screen and (max-width: 1440px) {
      min-width: 152px;
    }

    h2 {
      text-align: left;
      font-size: 24px;
      font-weight: 500;
    }

    .typeFilterItems {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      flex-wrap: wrap;

      .foodTypeOption {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 18px;
        color: #7e7e7e;
        font-family: Lato;

        input[type="checkbox"] {
          opacity: 1;
          width: 20px;
          height: 20px;
          border-radius: 50%;

          background-size: 100%;

          cursor: pointer;
          appearance: none;
          background-color: transparent;
          transition: background-color 0.3s, border-color 0.3s;

          &:checked {
            + span {
              opacity: 1;
            }
          }

          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
          }
        }

        span {
          color: #000;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 100% */
          text-transform: uppercase;
          opacity: 0.5;
          transition: color 0.3s; /* Smooth transition for color change */
        }

        &.active {
          span {
            opacity: 1 !important;
          }
        }
      }
    }
  }

  .menuItemsWithTypes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4.37%;
    width: 100%;
  }
  .menuItems,
  .skeletonWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
  }
  .menuItem {
    z-index: 1;
    text-decoration: none;
    display: flex;
    width: 100%;
    align-self: stretch;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 1px solid #ecece5;
    background: #fff;
    transition: all 0.3s;
    &:hover {
      .itemImage {
        img {
          transition: all 0.3s;

          scale: 1.1;
        }
      }
    }
    .itemImage {
      transition: all 0.3s;

      img {
        transition: all 0.5s;

        max-width: 250px;
        max-height: 250px;
        height: auto !important;
      }
    }
    .itemContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      align-self: stretch;
      height: fit-content;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        h4 {
          color: #000;
          text-align: center;
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px; /* 150% */
          text-transform: uppercase;
        }
        .block {
          width: 110px;
          height: 10px;
          background: #dc2268;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        font-family: Varela;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
      }
      span {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
        text-transform: uppercase;
      }
    }
  }

  .skeleton {
    .itemImageSkeleton {
      width: 180px;
      height: 180px;
      background-color: #e0e0e0;
      border-radius: 50%;
      animation: pulse 1.5s infinite;
    }

    .titleSkeleton {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .titleBlockSkeleton {
        width: 60%;
        height: 27px;
        background-color: #e0e0e0;
        border-radius: 5px;
        animation: pulse 1.5s infinite;
      }

      .blockSkeleton {
        width: 110px;
        height: 10px;
        background-color: #e0e0e0;
        animation: pulse 1.5s infinite;
        border-radius: 5px;
      }
    }

    .descriptionSkeleton {
      width: 80%;
      height: 21px;
      background-color: #e0e0e0;
      animation: pulse 1.5s infinite;
      border-radius: 5px;
    }

    .priceSkeleton {
      width: 40%;
      height: 30px;
      background-color: #e0e0e0;
      animation: pulse 1.5s infinite;
      border-radius: 5px;
    }
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    span {
      width: 100%;
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px; /* 156.25% */
      text-transform: uppercase;
    }

    .certificatesWrapper {
      display: flex;
      gap: 13px;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 37px;
        aspect-ratio: 1;
      }
    }
  }
  .buttons {
    width: 100%;
    margin-bottom: 50px;
    padding: 0 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

    a {
      display: flex;
      padding: 5px 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 40px;
      backdrop-filter: blur(12.5px);
      align-self: stretch;
      width: 33%;
      height: 40px;
      flex-grow: 1;
      max-width: 300px;
    }
    .wolt {
      background: #52bcdd;
      transition: all 0.3s;
      &:hover {
        background: #3e9fbd;
      }
    }
    .lieferando {
      background: #ff8000;
      transition: all 0.3s;
      &:hover {
        background: #e67300;
      }
    }
    .uberEats {
      background: #000;
      transition: all 0.3s;
      &:hover {
        background: #353535;
      }
    }
  }
  .companyLogos {
    width: auto;
    max-height: 30px;
  }

  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
}
