.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 27px 50px 67px 50px;

  .content {
    max-width: 1536px;
    width: 100%;

    .backButton {
      margin-bottom: 34px;

      path {
        fill: #eb578f;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          fill: #dc2268;
          transition: 0.1s;
        }
      }
    }

    .thumbnail {
      width: 100%;
      margin-bottom: 34px;
      border-radius: 15px;
    }

    .poster {
      display: flex;
      align-items: center;
      gap: 18px;
      margin-bottom: 63px;

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100px;
      }

      .dot {
        width: 2px;
        height: 2px;
        border-radius: 100px;
        background-color: #3592ff;
        margin-right: -14px;
      }

      p {
        color: #494949;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 0 !important;
      }

      a {
        color: #3592ff;
        font-family: Inter;
        font-size: 14.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 165.517% */
        text-decoration: none;
        margin-bottom: -1px; //corrects some misalignment
      }
    }

    h4 {
      color: #000;
      font-family: Inter;
      font-size: 28.375px;
      font-style: normal;
      font-weight: 600;
      line-height: 38.4px; /* 135.33% */
      margin-bottom: 36px;
    }

    p,li {
      margin-bottom: 30px;
      color: #000;
      font-family: Inter;
      font-size: 15.75px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px; /* 171.429% */
    }
    
    li {
      margin-bottom: 10px;
    }

  }
}

@media screen and (max-width:1080px) {
  .wrapper {
    padding: 5%;
    margin-top: 60px;

    .content {
      
      .backButton {
        width: 45px;
        height: 45px;
        margin-bottom: 20px;
      }
    }

    .thumbnail {
      margin-bottom: 10px !important;
    }

    .poster {
      margin-bottom: 20px !important;
    }

    h4 {
      margin-bottom: 20px !important;
    }
  }
}